import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  detailModal(event) {
    const id = event.currentTarget.dataset.id;

    if (!id) {
      event.preventDefault();
      return;
    }
    const frame = document.getElementById("modal-frame");
    frame.src = "/bundles/" + id;
  }
}
