import { Controller } from "stimulus"

export default class extends Controller {
  closeModal() {
    this.element.reset();

    const myModal = bootstrap.Modal.getInstance(document.getElementById('modal'));
    myModal.hide();

    const frame = document.getElementById('modal-frame');
    frame.src = '';
  }
}
