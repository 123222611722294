import { Controller } from "stimulus";

// Connects to data-controller="production-chart"
export default class extends Controller {
  static targets = ['date', 'timestamp'];

  connect() {
    this.daySwitchHour = 12;
    this.home();
    setInterval(() => { this.refresh(); }, 30000);
  }

  nextDate(date) {
    date.setDate(date.getDate() + 1);
  }

  prevDate(date) {
    date.setDate(date.getDate() - 1);
  }

  dateToStr(date) {
    return date.toISOString().substring(0,10);
  }

  updateDateField(date) {
    this.dateTarget.value = this.dateToStr(this.date);
  }

  next() {
    this.nextDate(this.date);
    this.updateDateField();
    this.refresh();
  }

  prev() {
    this.prevDate(this.date);
    this.updateDateField();
    this.element.requestSubmit();
  }

  home() {
    this.date = new Date();
    if (this.date.getHours() >= this.daySwitchHour) {
      this.nextDate(this.date);
    }

    this.updateDateField();
    this.refresh();
  }

  refresh() {
    this.timestampTarget.value = (new Date()).toISOString();
    this.element.requestSubmit();
  }
}
