import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["timer"]

  timerMinutes = null;
  overdueMargin = 10;

  connect() {
    this.overdueMargin = this.element.dataset.overdue || 10;
    this.timerMinutes = this.parseMinutes();
    if (this.timerMinutes == null) {
      return;
    }

    setInterval(() => {
      this.timerMinutes++;
      this.timerTarget.innerHTML = `${this.timerMinutes}m`;
      this.markOverdue()
    }, 60_000);
  }

  parseMinutes() {
    const match = this.timerTarget.dataset.minutes.match(/(\d+)m/);
    if (match) {
      return parseInt(match[1]);
    }
    return null;
  }

  markOverdue() {
    if (this.timerMinutes > this.overdueMargin && this.element.dataset.status !== "ready") {
      this.element.classList.add("card-overdue");
    }
  }

  toggle() {
    this.element.classList.toggle("folded");
  }

  payModal(event) {
    const id = event.target.dataset.id;
    if (!id) {
      event.preventDefault();
      return;
    }
    const frame = document.getElementById("modal-frame");
    frame.src = '';
    frame.src = "/terminal/orders/" + id;
  }

  shipmentModal(event) {
    const id = event.target.dataset.id;
    if (!id) {
      event.preventDefault();
      return;
    }
    const frame = document.getElementById("modal-frame");
    frame.src = '';
    frame.src = "/terminal/orders/" + id + "/shipments";
  }
}
