import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["price", "form", "spinner", "quantity"];

  connect() {
    const element = this.formTarget;
    const spinner = this.spinnerTarget
    const price = this.priceTarget;

    if (element == null || spinner == null || price == null) {
        return;
    }
    element.addEventListener("ajax:send", (event) => {
        spinner.classList.remove('d-none');
        price.classList.add('d-none');
    });
    element.addEventListener("ajax:complete", (event) => {
        spinner.classList.add('d-none');
        price.classList.remove('d-none');
    });
    element.addEventListener("ajax:success", (event) => {
        // alert("Added to cart successfully!");
        //const [_data, _status, xhr] = event.detail;
        //element.insertAdjacentHTML("beforeend", xhr.responseText);
    });
    element.addEventListener("ajax:error", () => {
        alert("Error while adding to cart!");
        //element.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
    });
  }

  setPrice(event) {
    const currency = this.priceTarget.innerText.split(" ")[1];
    const quantity = parseInt(this.quantityTarget.value) || 0;
    const productPrice = Number(this.priceTarget.dataset.basePrice);
    let newPrice = productPrice + [...document.querySelectorAll("[data-addon-price]:checked")]
      .reduce((acc, addon) => Number(addon.dataset.addonPrice) + acc, 0);

    newPrice *= quantity;

    this.priceTarget.innerText = newPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).replace('.', ',') + " " + currency;
  }
}