import { Controller } from "stimulus";

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  connect() {
  }

  submit(event) {
    event.preventDefault();
    event.target.form.requestSubmit();
  }
}
