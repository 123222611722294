import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  add(event) {
    const input = this.inputTarget;
    const value = parseInt(input.value) || 0;
    const add = parseInt(event.currentTarget.dataset.add) || 1;
    const newValue = Math.max(1, value + add);
    input.value = newValue;
    input.dispatchEvent(new Event("change"));
  }
}