// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("channels")

import Cookies from 'js-cookie';
window.Cookies = Cookies;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

window.getCsrfToken = function() {
    return document.querySelector("meta[name='csrf-token']").getAttribute('content');
}
window.railsFetch = function(req, init) {
    const request = new Request(req, init)
    request.headers.set('X-Requested-With', 'XMLHttpRequest')
    if (request.method !== 'GET') request.headers.set('X-CSRF-Token', getCsrfToken())
    // request.credentials = 'same-origin'
    return window.fetch(request)
}
